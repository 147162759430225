import createBootError from 'owa-shared-start/lib/createBootError';
import getScopedPath from 'owa-url/lib/getScopedPath';
import getSessionData from './utils/getSessionData';
import { getApp, getOwsPath } from 'owa-config';
import { getConfig, updateServiceConfig } from 'owa-service/lib/config';
import { getCookie } from 'owa-config/lib/universalCookies';
import { isBootFeatureEnabled } from 'owa-metatags';
import { isMsalEnabled } from 'mini-common-utils/lib/utils/isMsalEnabled';
import { markEnd, markFunction, markStart, trackBottleneck } from 'owa-performance';
import { onBootComplete } from 'owa-shared-start/lib/onBootComplete';
import { onBootError } from 'owa-shared-start/lib/onBootError';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import { setAriaTenantToken } from 'owa-shared-start/lib/ariaUtils';
import { setAuthTiming } from 'owa-auth-timings';
import { setBootFailureCount } from 'owa-shared-start/lib/bootErrorCounter';
import { setIsDeepLink } from 'owa-url/lib/isDeepLink';
import { setThreadName } from 'owa-thread-config';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import type StartConfig from './types/StartConfig';
import type { PromiseWithKey } from 'owa-performance';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import type { MailboxInfo } from 'owa-client-types';
import {
    lazyGetAnchorMailbox,
    lazyGetAuthTokenMsal,
    lazyCreateMsalInstance,
    lazyOnActivityTimeoutErrorForMSAL,
    lazyAcquireAccessTokenMsal,
} from 'owa-msaljs/lib/lazyFunctions';

let startTime: number;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any --
 * See aka.ms/mini-lint-errors
 *	> Unexpected any. Specify a different type. */
export async function miniStart(config: StartConfig): Promise<any> {
    try {
        await internalStart(config);

        try {
            // Unblocking lazy-loading post successful boot
            unblockLazyLoadCallbacks();
            onBootComplete(config, startTime);

            // call loader removed callbacks
            config.onLoaderRemoved?.();

            setBootFailureCount(0);
        } catch (error) {
            throw createBootError(error, 'BootComplete');
        }
    } catch (bootError) {
        // Shared boot error logic with big OWA which uses default handleBootError function in owa-shared-start
        onBootError(bootError, config);
    }
}

async function internalStart(config: StartConfig) {
    try {
        startTime = Date.now();
        // @ts-ignore - This flight does not exist in big OWA
        if (isBootFeatureEnabled('mini-analytics-worker')) {
            // Setting main thread name for supporting analytics worker
            setThreadName('MAIN_THREAD');
        }

        // don't reset the app if it is already set
        if (!getApp()) {
            // @ts-ignore - The App string is different for Mini
            setApp(config.app);
        }

        setIsDeepLink(config.isDeepLink);

        setAriaTenantToken(config.startupAriaToken);

        if (config.runBeforeStart) {
            markStart('rbsp');
            await config.runBeforeStart(config).catch(error => {
                if (!error.source) {
                    error.source = 'BeforeBoot';
                }
                throw error;
            });
            markEnd('rbsp');
        }

        updateServiceConfig({
            baseUrl: getScopedPath(getOwsPath()),
            appName: config.serverAppName,
        });

        let authToken: string | undefined;
        // MSAL creation needs to happen *after* runBeforeStart; in hosted scenarios TeamsJS
        // is initialized in runBeforeStart and sets up the NAA bridge that MSAL uses during init.
        // MSAL should not overwrite any existing getAuthToken callback if one was set up in runBeforeStart,
        // for example hosted auth that relies on Hub SDK and does not yet support MSAL.
        if (isMsalEnabled() && !getConfig().getAuthToken) {
            markStart('msal');
            if (getCookie('msal.cache.encryption')) {
                setAuthTiming('crypt');
            }
            setAuthTiming('msalis');
            // Create the MSAL instance with the correct app-specific parameters here in order to
            // be able to make the startupdata request before we start evaluating other boot bundles
            await lazyCreateMsalInstance.importAndExecute(config.msalConfiguration);
            setAuthTiming('msalie');

            setAuthTiming('fgatmsals');
            authToken = await lazyGetAuthTokenMsal.importAndExecute();
            setAuthTiming('fgatmsalsc');

            const getAuthToken = (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) =>
                lazyGetAuthTokenMsal.importAndExecute(headers, mailboxInfo);

            const getMsalToken = (
                mailboxInfo: MailboxInfo,
                resource?: string,
                scope?: string,
                correlationId?: string,
                wwwAuthenticateHeader?: string
            ) =>
                lazyAcquireAccessTokenMsal.importAndExecute(
                    mailboxInfo,
                    resource,
                    scope,
                    undefined /* headers */,
                    correlationId,
                    wwwAuthenticateHeader
                );

            updateServiceConfig({
                getMsalToken,
                getAuthToken,
                getAnchorMailbox: (mailboxInfo?: MailboxInfo) =>
                    lazyGetAnchorMailbox.importAndExecute(mailboxInfo),
                onAuthFailed: () => {},
                onActivityTimeoutError: () => lazyOnActivityTimeoutErrorForMSAL.importAndExecute(),
                isMsalFlowEnabled: true,
            });
            markEnd('msal');
        }

        const sessionDataPromise =
            config.overrideBootPromises?.(authToken) ?? getSessionData(authToken);

        const javascriptPromise = markFunction(() => config.bootstrap.import(), 'mjs')();

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
         * See aka.ms/mini-lint-errors
         *	> Unexpected any. Specify a different type. */
        const bootPromises: PromiseWithKey<any>[] = [
            { promise: sessionDataPromise, key: 'sd' },
            { promise: javascriptPromise, key: 'js' },
        ];

        // TODO: Do we need getOpxHostData? Since it's already included in bootstrap flow

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
         * See aka.ms/mini-lint-errors
         *	> Unexpected any. Specify a different type. */
        const bootstrapPromises: Promise<any>[] = [javascriptPromise];

        bootPromises.push({
            promise: Promise.all(bootstrapPromises)
                .then(([bootFunc]) => {
                    try {
                        return bootFunc.apply(null, [sessionDataPromise]);
                    } catch (e) {
                        throw createBootError(e, 'Bootstrap');
                    }
                })
                .catch(e => {
                    throw createBootError(e, 'Script');
                }),
            key: null,
        });

        if (config.runAfterRequests) {
            config.runAfterRequests(sessionDataPromise);
        }

        return trackBottleneck('start', bootPromises);
    } catch (error) {
        return Promise.reject(createBootError(error, 'Preboot'));
    }
}
